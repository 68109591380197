import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Software Developer",
          "Experienced MEAN Stack Developer",
          "Experienced MERN Stack Developer",
          "Experienced MEVN Stack Developer",
          "Experienced Laravel Developer",
          "Experienced CakePHP Stack Developer",
          "Experienced DevOPS (AWS, DO, Vultr, Docker)",
          "Open Source Contributor",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 10,
        delay: 50
      }}
    />
  );
}

export default Type;
